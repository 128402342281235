import { delay } from '@st/utils'
import { useFavoriteGamesStore } from '../stores/useFavoriteGamesStore'

export function useFavoriteGame(gameId: number | Ref<number>) {
  const id = computed(() => toValue(gameId))
  const { addFavortiteGame, deleteFavortiteGame, checkGameFavorite } =
    useFavoriteGamesStore()

  const isFavoriteGame = computed(() => checkGameFavorite(id.value))

  const shakeAnimation = ref(false)
  async function animateFavoriteIcon() {
    shakeAnimation.value = true
    await delay(300)
    shakeAnimation.value = false
  }

  function handleFavoriteClick() {
    animateFavoriteIcon()
    return isFavoriteGame.value
      ? deleteFavortiteGame(id.value)
      : addFavortiteGame(id.value)
  }

  const favoriteGameIconColor = computed(() =>
    isFavoriteGame.value ? '#fb1038' : undefined,
  )

  return {
    handleFavoriteClick,
    animateFavoriteIcon,
    shakeAnimation,
    isFavoriteGame,
    favoriteGameIconColor,
  }
}
